import { FooterConversionCard } from '../components/AudiogramHome/Greetings';
import { LocalStorageItems, getItem } from '../utils/generalUtilities';
import { NextSeo } from 'next-seo';
import { ScreenTypes } from '@/hooks/useScreenType';
import { SectionContent } from '../components/OldGeneralComponents';
import { useScreenType } from '@/hooks/useScreenType';
import BackgroundGraphics from '../assets/images/seo/background_gif.svg';
import BusinessSection from '@/components/AiVideoHome/BusinessSection';
import Examples from '@/components/AiVideoHome/Examples';
import FeaturesDetails from '../components/AudiogramHome/FeaturesDetails';
import FeaturesList from '@/components/AiVideoHome/FeaturesList';
import Footer from '../components/Footer';
import HeroBanner from '../components/AiVideoHome/Banner';
import Logos from '../components/AudiogramHome/Logos';
import MarketingConversion from '@/components/AiVideoHome/Marketing';
import MoreFeatures from '../components/AudiogramHome/MoreFeatures';
import React, { useEffect, useState } from 'react';
import VideoGif from '../components/AudiogramHome/VideoGif';
import WhatCreatorsSay from '@/components/AiVideoHome/WhatCreatorsSay';
import locale from '../locales/en/NativeLanding';
import useSectionContentWidth from '../hooks/useSectionContentWidth';
const MAIN_VISUAL_URLS = {
  image:
    'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/new_index/2024/banner.png',
  video:
    'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/new_index/2024/banner_updated.mp4'
};
export const title = locale.seo.title;
export const description = locale.seo.meta_desc;

const Index = (): JSX.Element => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    try {
      const accessToken = getItem(LocalStorageItems.ACCESS_TOKEN);
      if (!accessToken) return;
      setIsLoggedIn(accessToken && accessToken.length > 0);
    } catch (e) {
      console.error(e);
    }
  }, []);
  const { sectionWidthInPixels, sectionWidth } = useSectionContentWidth();
  const screenType = useScreenType();
  const isSmallScreen = screenType === ScreenTypes.mobile || ScreenTypes.tab;
  return (
    <>
      <NextSeo
        title={title}
        description={description}
        openGraph={{
          title: title,
          description: description,
          images: [
            {
              url: 'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/new_index/images/meta_image.jpg'
            }
          ]
        }}
        twitter={{
          handle: '@jupitrr_ai',
          site: '@jupitrr_ai',
          cardType: 'summary_large_image'
        }}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: 'https://d3enyft5v5gx6r.cloudfront.net/website/SEO/favicon.png'
          }
        ]}
      />
      <div className="mb-8 flex w-full flex-col md:mb-0">
        <HeroBanner />
        <div className="relative">
          <div
            className="absolute inset-0 mt-[80px] bg-blue-600"
            style={{
              backgroundImage: `url(${BackgroundGraphics.src})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            }}
          />
          <SectionContent
            maxWidth={sectionWidthInPixels}
            className="absolute left-0 top-0 z-20"
          >
            <VideoGif
              videoSrc={MAIN_VISUAL_URLS.video}
              placeholderImageSrc={MAIN_VISUAL_URLS.image}
              className="h-[489px] w-full rounded-lg"
            />
          </SectionContent>
        </div>
      </div>
      <Logos />
      <MarketingConversion />
      <FeaturesDetails />
      <BusinessSection />
      <WhatCreatorsSay />
      <MoreFeatures />
      <FooterConversionCard />
      <Footer />
    </>
  );
};

const IndexPage = () => <Index />;
export default IndexPage;
